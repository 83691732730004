<template>
  <div class="bgStyle wrapper">
    <div class="head">
      <a-space :size="24">
        <a-input
          placeholder="输入文档名称或文档编码"
          v-model="docname"
          style="width: 200px"
          allowClear
        />
        <a-select
          v-model="doctype"
          style="width: 180px"
          ref="select"
          placeholder="选择文档类型"
          allowClear
        >
          <a-select-option
            v-for="item in arrDocTypes"
            :value="item.id"
            :key="item.id"
            >{{ item.codeName }}</a-select-option
          >
        </a-select>

        <a-button type="primary" @click="search">查询</a-button>
      </a-space>
      <a-button type="primary" @click="showModal">新增图文档</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :rowKey="(record) => record.id"
      bordered
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="serial" slot-scope="text, record, index">
        {{ index + 1 + skipCount }}
      </template>
      <template slot="docName" slot-scope="text, record">
        <a :title="text" @click="whenEditRow(record)">{{ text }}</a>
      </template>
      <template slot="docType" slot-scope="text">
        {{ filterType(text) }}
      </template>
      <template slot="docSize" slot-scope="text">
        {{ text + "KB" }}
      </template>
      <template slot="creationTime" slot-scope="text">
        {{ text | filterDate }}
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item key="2" @click="whenEditRow(record)">
              查看详情
            </a-menu-item>
            <a-menu-item key="3">
              <a
                :href="record.docUrl"
                target="blank"
                @click="whenDownload(record)"
                >下载</a
              >
            </a-menu-item>
            <a-menu-item key="4" @click="whenHistoryRow(record)">
              历史版本
            </a-menu-item>
            <a-menu-item key="5" @click="whenDeleteRow(record)">
              删除
            </a-menu-item>
          </a-menu>
          <a> 操作 <a-icon type="down" /> </a>
        </a-dropdown>
      </template>
    </a-table>

    <a-modal
      v-model="visibleNew"
      title="新增图文档"
      :width="1200"
      :maskClosable="false"
      :cancel-button-props="{ style: { display: 'none' } }"
      :ok-button-props="{ style: { display: 'none' } }"
    >
      <add-doc ref="refAddDoc" @uploadOk="whenUploadOk" />
    </a-modal>

    <a-modal
      v-model="visibleHistory"
      title="历史版本"
      :width="1000"
      :maskClosable="false"
      :footer="null"
      :destroyOnClose="true"
    >
      <history-version :figureDocumentId="figureDocumentId" :id="id" />
    </a-modal>
  </div>
</template>

<script>
import AddDoc from "./components/AddDoc.vue";
import HistoryVersion from "./components/HistoryVersion.vue";
import {
  getAllFigureDocTypes,
  getList,
  deleteFigureDoc,
  create,
  getByCodeKeyPath,
  addDownLoadFigureDocOperateLog,
} from "@/api/figureDocument.js";
import uniqBy from "lodash/uniqBy";

const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    ellipsis: true,
  },
  {
    title: "文档名称",
    dataIndex: "docName",
    scopedSlots: { customRender: "docName" },
    ellipsis: true,
  },
  {
    title: "文档编码",
    dataIndex: "docCode",
    ellipsis: true,
  },
  {
    title: "文档类型",
    dataIndex: "docType",
    scopedSlots: { customRender: "docType" },
    width: 100,
  },
  {
    title: "版本",
    dataIndex: "docVersion",
    width: 100,
    ellipsis: true,
  },
  {
    title: "文档格式",
    dataIndex: "docFormat",
  },
  {
    title: "大小",
    dataIndex: "docSize",
    scopedSlots: { customRender: "docSize" },
  },
  {
    title: "上传人",
    dataIndex: "docUploader",
    ellipsis: true,
  },
  {
    title: "上传时间",
    dataIndex: "creationTime",
    scopedSlots: { customRender: "creationTime" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
    width: 100,
  },
];

export default {
  name: "DocumentIndex",
  components: {
    AddDoc,
    HistoryVersion,
  },
  data() {
    return {
      docname: "",
      doctype: undefined,

      data: [],
      columns,

      visibleNew: false,
      visibleHistory: false,

      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        skipCount: 0,
      },
      arrDocTypes: [],
      objDocType: {},

      figureDocumentId: "",
      id: "",
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  created() {
    this.getDocType();
  },
  methods: {
    whenDeleteRow(row) {
      const _this = this;
      this.$confirm({
        title: "确认删除" + row.docName + "?",
        onOk() {
          let figureDocumentId = row.figureDocumentId;
          deleteFigureDoc(figureDocumentId).then(() => {
            _this.$message.success("删除成功");

            _this.pagination.current = 1;
            _this.getData();
          });
        },
      });
    },
    whenHistoryRow(row) {
      this.visibleHistory = true;
      this.figureDocumentId = row.figureDocumentId;
      this.id = row.id;
    },
    whenEditRow(row) {
      this.$router.push({
        name: "FigureDocManageDetail",
        params: {
          figureDocumentId: row.figureDocumentId,
          id: row.id,
          edit: true,
          isFrom: "list",
        },
      });
    },
    whenDownload(row) {
      getByCodeKeyPath("HS_TWD_CZLX_XZ").then((res) => {
        let params = {
          figureDocumentId: row.figureDocumentId,
          operateType: res.id,
        };
        addDownLoadFigureDocOperateLog(params);
      });
      this.$message.info("下载成功!");
    },
    whenViewRow(row) {
      this.$router.push({
        name: "FigureDocManageDetail",
        params: {
          figureDocumentId: row.figureDocumentId,
          edit: false,
          isFrom: "list",
        },
      });
    },
    getDocType() {
      getAllFigureDocTypes().then((res) => {
        console.log("getDocType", res);

        this.arrDocTypes = res || [];
        this.getObjDocType(res);

        this.getData();
        localStorage.setItem("arrDocTypes", JSON.stringify(this.arrDocTypes));
      });
    },
    getObjDocType(arr) {
      let _this = this;
      arr.forEach((item) => {
        _this.objDocType[item.id] = item.codeName;
      });
      console.log("objDocType", this.objDocType);
      localStorage.setItem("objDocType", JSON.stringify(this.objDocType));
    },
    filterType(val) {
      return this.objDocType[val] || "";
    },
    getData() {
      let pageSize = this.pagination.pageSize;
      let skipCount = (this.pagination.current - 1) * pageSize;

      let params = {
        docType: this.doctype,
        key: this.docname,
        skipCount,
        maxResultCount: pageSize,
      };

      getList(params).then((res) => {
        console.log("getList", res);
        this.data = res.items;
        this.pagination.total = res.totalCount;
        this.skipCount = params.skipCount;
      });
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getData();
    },
    search() {
      this.pagination.current = 1;
      console.log(this.docname, this.doctype);
      this.getData();
    },
    showModal() {
      this.visibleNew = true;
    },

    //开始上传
    whenUploadOk() {
      let aliyunFileDtos = this.$refs.refAddDoc.aliyunOssFileDtos;
      let param = {
        aliyunOssFileDtos: aliyunFileDtos,
      };
      console.log("param:", param);
      create(param)
        .then((res) => {
          this.visibleNew = false;
          this.$refs.refAddDoc.clearClick();
          this.getData();
          this.$message.success("上传成功!");
        })
        .catch((errorMsg) => {
          this.$refs.refAddDoc.loading = false;
          console.log("errorMsg:", errorMsg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #fff !important;
}

.editable-row-operations a {
  margin-right: 8px;
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
</style>
