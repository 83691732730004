<template>
  <a-spin :spinning="spinning">
    <div class="add-doc">
      <div class="top">
        <div class="tit">选择文件</div>
        <a-upload-dragger
          name="file"
          :multiple="true"
          :before-upload="beforeUpload"
          :file-list="fileList"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">
            可拖拽或者点击此区域进行上传(支持文件多选上传、不支持文件夹上传)
          </p>
        </a-upload-dragger>
      </div>

      <div class="tit">文件清单</div>
      <div class="clear">
        <a-button @click="clearClick">清空列表</a-button>
      </div>
      <a-row :gutter="26">
        <a-col :span="24">
          <a-table
            bordered
            :columns="columns"
            :data-source="dataSource"
            :rowKey="(record) => record.fileGuid"
            :pagination="pagination"
            @change="handleTableChange"
          >
            <template slot="serial" slot-scope="text, record, index">
              {{ filterSerial(index) }}
            </template>
            <template slot="docSize" slot-scope="text">
              {{ Math.ceil(text / 1024) + "KB" }}
            </template>
            <template slot="docExtension" slot-scope="text">
              {{ text.substring(1) }}
            </template>
            <template slot="docType" slot-scope="text, record">
              <editableselect-cell
                :text="text"
                @change="onCellChange(record.fileGuid, 'docType', $event)"
              />
            </template>
            <template slot="operation" slot-scope="text, record, index">
              <a-space :size="20">
                <a @click="changeAll(record)"
                  ><a-icon type="snippets" :style="{ fontSize: '26px' }"
                /></a>
                <a @click="del(index)"
                  ><a-icon type="delete" :style="{ fontSize: '26px' }"
                /></a>
              </a-space>
            </template>
          </a-table>
        </a-col>
      </a-row>
      <div class="startUpload">
        <a-button type="primary" @click="startUpload" :loading="loading"
          >开始上传</a-button
        >
      </div>
    </div>
  </a-spin>
</template>
<script>
import EditableselectCell from "./EditableselectCell.vue";
import OSS from "ali-oss";
import {
  UploadFileToAliyunTempPosition,
  getAllFigureDocTypes,
  getAliyunOssSettings,
} from "@/api/figureDocument.js";

export default {
  components: {
    EditableselectCell,
  },
  data() {
    return {
      dataSource: [],
      arrDocTypes: [],
      objDocType: {},
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" },
        },
        {
          title: "文档名称",
          dataIndex: "fileOrigionName",
        },
        {
          title: "版本",
          dataIndex: "version",
        },
        {
          title: "文档格式",
          dataIndex: "fileExtension",
          scopedSlots: { customRender: "docExtension" },
        },
        {
          title: "大小",
          dataIndex: "fileSize",
          scopedSlots: { customRender: "docSize" },
        },
        {
          title: "文档类型",
          dataIndex: "docType",
          scopedSlots: { customRender: "docType" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],

      fileList: [],
      docTypeId: "",

      spinning: false,

      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },

      canUpload: false,

      aliyunOssSettingDto: {},
      aliyunOssFileDtos: [],
      loading: false,
    };
  },
  created() {
    this.getDocArr();
    this.getAliOssSeting();
  },
  methods: {
    getDocArr() {
      getAllFigureDocTypes().then((res) => {
        console.log("getDocType", res);

        this.arrDocTypes = res || [];
        localStorage.setItem("arrDocTypes", JSON.stringify(this.arrDocTypes));
        this.getObjDocType(this.arrDocTypes);
      });
    },
    getObjDocType(arr) {
      let _this = this;
      arr.forEach((item) => {
        _this.objDocType[item.id] = item.codeName;
      });
      console.log("objDocType", this.objDocType);
      localStorage.setItem("objDocType", JSON.stringify(this.objDocType));
    },
    filterSerial(index) {
      let pageSize = this.pagination.pageSize;
      let current = this.pagination.current - 1;
      let num = current * pageSize;
      return num + index + 1;
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      console.log("handleChange", this.pagination);
    },
    onCellChange(key, dataIndex, value) {
      this.dataSource.forEach((item) => {
        if (item.fileGuid == key) {
          item.docType = value;
        }
      });

      console.log("change-edit", this.dataSource);
    },
    beforeUpload(file) {
      console.log("file", file);
      if (file.size) {
        if (this.fileList.findIndex((p) => p.name == file.name) < 0) {
          this.fileList = [...this.fileList, file];

          let fileType = "";
          let fileExtension = file.name.substr(file.name.lastIndexOf("."));
          if (
            file.type === "" ||
            file.type === undefined ||
            file.type === null
          ) {
            fileType = this.getFileContentType(fileExtension);
          } else {
            fileType = file.type;
          }

          var obj = {
            fileOrigionName: file.name.substr(0, file.name.lastIndexOf(".")),
            version: "V1.0",
            fileExtension: fileExtension,
            fileSize: file.size,
            docType: this.arrDocTypes[0].id,
            fileContentType: fileType,
            file: file,
          };

          //去掉重复的
          let index = this.dataSource.findIndex(
            (p) =>
              p.fileOrigionName == obj.fileOrigionName &&
              p.fileExtension == obj.fileExtension
          );
          if (index < 0) {
            this.dataSource.push(obj);
          }

          console.log("datasource:", this.dataSource);

          this.canUpload = true;
        }
      } else {
        this.$message.error("无效的文件，请重新上传！");
        this.canUpload = false;
        this.spinning = false;
        this.fileList = [];
        this.dataSource = [];
      }

      return false;
    },

    handleChange(info) {
      console.log("info", info);
      if (this.canUpload) {
        this.spinning = true;

        if (info.fileList.length == this.fileList.length) {
          const formData = new FormData();
          this.fileList.forEach((file) => {
            formData.append("files[]", file);
          });

          //this.handleUpload(formData);
        }
      }
    },

    handleUpload(formData) {
      UploadFileToAliyunTempPosition(formData).then((res) => {
        console.log("UploadFileToAliyunTempPosition", res);
        res.forEach((item) => {
          item.docType = this.arrDocTypes[0].id;
          item.version = "V1.0";
        });

        this.dataSource = this.dataSource.concat(res);

        this.fileList = [];
        this.spinning = false;
      });
    },
    setDefaultDocType() {
      let arr = [...this.dataSource];
      this.dataSource = [];

      arr.forEach((item) => {
        item.docType = this.docTypeId;

        item.version = "V1.0";
      });

      this.$nextTick(() => {
        this.dataSource = arr;
        console.log("this.dataSource:", this.dataSource);
      });
    },
    changeAll(record) {
      console.log("------record", record);

      this.docTypeId = record.docType;
      this.setDefaultDocType();
    },
    del(index) {
      let pageSize = this.pagination.pageSize;
      let current = this.pagination.current - 1;
      let num = current * pageSize + index;
      this.dataSource.splice(num, 1);

      this.fileList.splice(num, 1);

      this.pagination.current = 1;
    },
    clearClick() {
      console.log("clearClick");

      this.fileList = [];
      this.dataSource = [];
      this.aliyunOssFileDtos = [];
      this.loading = false;
    },
    getFileContentType(extension) {
      var contentType = "";
      switch (extension.toLowerCase()) {
        case ".jpe":
        case ".jpeg":
        case ".jfif":
        case ".jpg":
          contentType = "image/jpeg";
          break;
        case ".biz":
        case ".cml":
        case ".dcd":
        case ".dtd":
        case ".ent":
        case ".fo":
        case ".math":
        case ".mml":
        case ".mtx":
        case ".rdf":
        case ".spp":
        case ".svg":
        case ".tld":
        case ".tsd":
        case ".vml":
        case ".vxml":
        case ".wsdl":
        case ".xdr":
        case ".xml":
        case ".xq":
        case ".xquery":
        case ".xql":
        case ".xsd":
        case ".xslt":
          contentType = "text/xml";
          break;
        case ".pdf":
          contentType = "application/pdf";
          break;
        case ".png":
          contentType = "image/png";
          break;
        case ".stl":
          contentType = "application/vnd.ms-pki.stl";
          break;
        case ".doc":
        case ".docx":
          contentType =
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          break;
        case ".zip":
          contentType = "application/x-zip-compressed";
          break;
        case ".rar":
          contentType = "application/x-rar-compressed";
          break;
        case ".7z":
          contentType = "application/x-7z-compressed";
          break;
        case ".3dm":
          contentType = "application/3dm";
          break;
        case ".3ds":
          contentType = "image/x-3ds";
          break;
        case ".aspx":
          contentType = "application/x-zip-compressed";
          break;
        case ".bmp":
          contentType = "image/bmp";
          break;
        case ".prt":
          contentType = "application/nx";
          break;
        case ".ai":
        case ".eps":
          contentType = "application/postscript";
          break;

        case ".xls":
          contentType = "application/vnd.ms-excel";
          break;
        case ".ppt":
          contentType = "application/vnd.ms-powerpoint";
          break;
        case ".vsdx":
          contentType = "application/vnd.ms-visio.viewer";
          break;
        case ".wps":
          contentType = "application/vnd.ms-works";
          break;
        case ".pptx":
          contentType =
            "application/vnd.openxmlformats-officedocument.presentationml.presentation";
          break;
        case ".xlsx":
        case ".xsl":
          contentType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          break;
        case ".ttc":
          contentType = "application/x-font-ttf";
          break;
        case ".gz":
          contentType = "application/x-gzip";
          break;
        case ".dll":
          contentType = "application/x-msdownload";
          break;
        case ".wav":
          contentType = "audio/wav";
          break;
        case ".gif":
          contentType = "image/gif";
          break;
        case ".tif":
          contentType = "image/tiff";
          break;
        case ".igs":
          contentType = "model/iges";
          break;
        case ".iges":
        case ".step":
        case ".txt":
        case ".creo":
        case ".obj":
        case ".stp":
        case ".x_t":
          contentType = "text/plain";
          break;
        case ".mov":
          contentType = "video/quicktime";
          break;
        default:
          contentType = "application/octet-stream";
          break;
      }
      return contentType;
    },

    //获取阿里云配置
    getAliOssSeting() {
      getAliyunOssSettings().then((res) => {
        this.aliyunOssSettingDto = res;
        console.log("aliyunOssSettingDto:", this.aliyunOssSettingDto);
      });
    },

    //开始上传
    startUpload() {
      this.loading = true;
      let arr = this.dataSource;
      console.log("arr", arr);
      if (arr.length == 0) {
        this.$message.error("请先选择文件，再进行上传！");
      } else {
        let promiseList = [];

        var client = new OSS({
          region: this.aliyunOssSettingDto.region,
          accessKeyId: this.aliyunOssSettingDto.accessKeyId,
          accessKeySecret: this.aliyunOssSettingDto.accessKeySecret,
          bucket: this.aliyunOssSettingDto.bucketName_HardWareSaas,
          secure: true,
          endpoint: this.aliyunOssSettingDto.endpoint,
        });

        arr.forEach((item) => {
          var fileExtension = item.fileExtension;
          var fileGuid = this.newGuid(); // 生成文件名
          var aliyunFullPath =
            this.aliyunOssSettingDto.projectName +
            "/" +
            this.aliyunOssSettingDto.fileDir +
            "/" +
            this.getNowDate() +
            "/" +
            fileGuid +
            fileExtension; //存储位置

          let p = new Promise((resolve, reject) => {
            client.multipartUpload(aliyunFullPath, item.file).then((result) => {
              var fileOssLink = result.res.requestUrls[0];
              if (fileOssLink.indexOf("?") > 0) {
                fileOssLink = fileOssLink.substr(0, fileOssLink.indexOf("?"));
              }
              var filePreviewLink = "";
              if (
                fileExtension.toLowerCase() == ".pptx" ||
                fileExtension.toLowerCase() == ".ppt" ||
                fileExtension.toLowerCase() == ".docx" ||
                fileExtension.toLowerCase() == ".doc" ||
                fileExtension.toLowerCase() == ".xlsx" ||
                fileExtension.toLowerCase() == ".xls"
              ) {
                filePreviewLink =
                  "http://view.officeapps.live.com/op/view.aspx?src=" +
                  fileOssLink;
              } else {
                filePreviewLink = fileOssLink;
              }

              let aliyunOssFileDto = {
                fileGuid: fileGuid,
                fileExtension: fileExtension,
                fileSize: item.fileSize,
                fileAliyunName: fileGuid,
                fileOrigionName: item.fileOrigionName,
                fileAliyunFullPath: aliyunFullPath,
                fileAliyunOssLink: fileOssLink,
                creationDate: new Date(),
                fileContentType: item.fileContentType,
                filePreviewAddress: filePreviewLink,
                docType: item.docType,
                version: item.version,
              };

              //console.log("aliyunOssFileDto",aliyunOssFileDto);
              resolve(aliyunOssFileDto);
            });
          });

          promiseList.push(p);
        });

        Promise.all(promiseList)
          .then((res) => {
            this.aliyunOssFileDtos = res;
            console.log("aliyunOssFileDtos:", this.aliyunOssFileDtos);

            if (this.aliyunOssFileDtos.length > 0) {
              this.$emit("uploadOk");
            } else {
              this.$message.error("没有上传成功的文件！");
              this.loading = false;
            }
          })
          .catch((reason) => {
            console.log(reason);
            this.loading = false;
          });
      }
    },

    //获取新的Guid
    newGuid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },

    //获取日期字符串，格式yyyyMMdd
    getNowDate() {
      var d = new Date();
      var YearStr = d.getFullYear().toString();
      var MonthStr =
        d.getMonth() + 1 < 10
          ? "0" + (d.getMonth() + 1).toString()
          : (d.getMonth() + 1).toString();
      var dayStr =
        d.getDate() < 10
          ? "0" + d.getDate().toString()
          : d.getDate().toString();
      return YearStr + MonthStr + dayStr;
    },
  },
};
</script>

<style lang="scss">
.add-doc {
  .ant-upload-list {
    display: none !important;
  }

  .top {
    margin-bottom: 16px;
  }

  .tit {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .clear {
    text-align: right;
    margin-bottom: 12px;
  }

  .startUpload {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
